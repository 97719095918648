import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthorization } from "../store/Authorization";

export default function PinGuard() {
  const { leaguePass } = useAuthorization();
  const navigate = useNavigate();

  const routePin = {
    0: "inactive",
    1: "active",
    2: "external",
  };

  useEffect(() => {
    if (leaguePass !== null) {
      navigate(`/pin/${routePin[leaguePass]}`);
    }
  }, [leaguePass, navigate]);

  return (
    <>
      <Outlet />
    </>
  );
}
