import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSignUp } from "../store/SignUp";
import { Grid, Paper } from "@mui/material";

export default function SignUpGuard() {
  const { email, isValidationCode } = useSignUp((state) => state.signUpState);
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      if (isValidationCode) {
        navigate("/signup/complete");
      } else {
        navigate("/signup/validate");
      }
    } else {
      navigate("/signup");
    }
  }, [email, isValidationCode, navigate]);

  return (
    <Grid
      item
      container
      flex={{ md: 1.5, xs: 2 }}
      flexDirection={"column"}
      justifyContent={{ xs: "flex-start", md: "center" }}
      alignItems={"center"}
    >
      <Paper sx={{ width: "85%" }}>
        <Outlet />
      </Paper>
    </Grid>
  );
}
