import { Grid } from "@mui/material";
import { lazy } from "react";
import { Outlet } from "react-router-dom";

export default function Index() {
  return (
    <Grid container alignItems={"center"} justifyContent={"center"} p={5}>
      <Outlet />
    </Grid>
  );
}
export const Account = lazy(() => import("./index"));
