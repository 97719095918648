import { Grid, Paper } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useForgotPassword } from "../store/ForgotPassword";

export default function ForgotPasswordGuard() {
  const { email, isValidationCode } = useForgotPassword((state) => state.data);
  const navigate = useNavigate();

  const basePath = "/forgot-password";

  useEffect(() => {
    if (email) {
      if (isValidationCode) {
        navigate(`${basePath}/complete`);
      } else {
        navigate(`${basePath}/validate`);
      }
    } else {
      navigate(`${basePath}`);
    }
  }, [email, isValidationCode, navigate]);

  return (
    <Grid
      item
      container
      flex={{ md: 1.5, xs: 2 }}
      flexDirection={"column"}
      justifyContent={{ xs: "flex-start", md: "center" }}
      alignItems={"center"}
    >
      <Paper sx={{ width: "85%" }}>
        <Outlet />
      </Paper>
    </Grid>
  );
}
