import { lazy } from "react";

const Active = lazy(() => import("./PinActive"));
const Inactive = lazy(() => import("./PinInactive"));
const External = lazy(() => import("./PinExternal"));

export const PinRescue = {
  Active,
  Inactive,
  External,
};
