import * as yup from "yup";

export type IFormSchema = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export type IRequestData = {
  currentPassword: string;
  newPassword: string;
};

export const initialData: IFormSchema = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const FormSchema = yup.object({
  currentPassword: yup
    .string()
    .required("*Campo obrigatório")
    .min(6, "Mínimo 6 caracteres")
    .max(30, "Máximo 30 caracteres"),
  newPassword: yup
    .string()
    .required("*Campo obrigatório")
    .min(6, "Mínimo 6 caracteres")
    .max(30, "Máximo 30 caracteres")
    .test(
      "no-leading-trailing-space",
      "A senha não pode começar ou terminar com espaços em branco",
      (value) => !value || value.trim() === value
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "*As senhas precisam ser iguais")
    .required("*Campo Obrigatório")
    .min(6, "Mínimo 6 caracteres")
    .max(30, "Máximo 30 caracteres")
    .test(
      "no-leading-trailing-space",
      "A senha não pode começar ou terminar com espaços em branco",
      (value) => !value || value.trim() === value
    ),
});
