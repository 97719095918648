import { AxiosError, AxiosResponse } from "axios";
import { ILoginFormData } from "../../pages/public/SignIn";
import { api } from "../../services/api";
import { useAlertError } from "../../store/AlertError";
import { useAuthentication } from "../../store/Authentication";
import { useGlobalLoading } from "../../store/GlobalLoading";
import { ResponseMessagesEnum } from "../../interfaces/Enums";

interface ILogin {
  request: (body: ILoginFormData) => Promise<void>;
}

export function useAuthLogin(): ILogin {
  const { login } = useAuthentication();
  const { openAlert } = useAlertError();
  const { closeLoading } = useGlobalLoading();

  async function request(body: ILoginFormData): Promise<void> {
    try {
      const { email, password } = body;
      const basicToken = `${email}:${password}`;
      const encodedToken = btoa(basicToken);

      const response = await api.post(
        "/authentication/signin",
        {},
        {
          headers: {
            Authorization: `Basic ${encodedToken}`,
          },
        }
      );

      setTimeout(() => {
        handleResponse(response);
        closeLoading();
      }, 1000);
    } catch (error: AxiosError | any) {
      handleError(error);
      closeLoading();
    }
  }

  function handleResponse(response: AxiosResponse): void {
    if (response.status === 200) {
      const headers = response.headers;

      openAlert({
        message: ResponseMessagesEnum.SuccessAuth,
        status: "success",
      });

      if (response.data) {
        login({
          authToken: headers["x-access-token"],
          csrfToken: headers["x-csrf-token"],
        });
      } else {
        console.error("Resposta inesperada do servidor:", response);
      }
    } else {
      throw new Error(response.data?.message || "Erro desconhecido");
    }
  }

  function handleError(error: AxiosError): void {
    const { response } = error;

    if (response) {
      const { status = 999, data } = response;
      console.log(error);

      openAlert({
        message: ResponseMessagesEnum.ErrorAuth,
        status: "warning",
      });
    } else {
      openAlert({
        message: "Erro de rede ou outro problema",
        status: "error",
      });
    }
  }

  return { request };
}
