import { create } from "zustand";
import { AssinaturaEpaySituacaoEnum } from "../interfaces/Enums";
import { persist } from "zustand/middleware";

type UseAuthProps = {
  leaguePass: AssinaturaEpaySituacaoEnum | null;
  setLeaguePass: (mode: AssinaturaEpaySituacaoEnum) => void;
};

export const useAuthorization = create(
  persist<UseAuthProps>(
    (set) => ({
      leaguePass: null,
      setLeaguePass: (mode) => {
        set((state) => {
          return {
            ...state,
            leaguePass: mode,
          };
        });
      },
    }),
    {
      name: "authorization",
    }
  )
);
