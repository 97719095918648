import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useGlobalLoading } from "../../store/GlobalLoading";
import { useModalStore } from "../../store/ModalStore";
import PlayStore from "../../assets/images/playstore_logo.png";
import AppStore from "../../assets/images/appstore_logo.png";

export function ConfirmPinRescue() {
  const { closeModal } = useModalStore();
  const { openLoading, closeLoading } = useGlobalLoading();

  const linksNba = [
    {
      href: "https://apps.apple.com/br/app/nba-app-oficial/id484672289",
      name: "AppStore",
      width: "48%",
      image: AppStore,
    },
    {
      href: "https://play.google.com/store/apps/details?id=com.nbaimd.gametime.nba2011&hl=pt_BR&gl=US",
      name: "PlayStore",
      width: "50%",
      image: PlayStore,
    },
  ];

  return (
    <>
      <DialogTitle>
        {"Parabéns, você resgatou seu NBA League Pass com Sucesso!"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Clique no link a baixo para acessar o App da NBA:
        </Typography>
        <Grid
          container
          flexDirection={"row"}
          flexWrap={"nowrap"}
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={2}
        >
          {linksNba.map((link) => (
            <Grid item width={link.width}>
              <a href={link.href} target="_blank">
                <img width={"100%"} src={link.image} alt={link.name} />
              </a>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          sx={{ color: "#17408B" }}
          onClick={() => {
            closeModal();
            openLoading();
            setTimeout(() => {
              closeLoading();
            }, 500);
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </>
  );
}
