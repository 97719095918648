import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { ResponseMessagesEnum } from "../../interfaces/Enums";
import { api } from "../../services/api";
import { useAlertError } from "../../store/AlertError";
import { useGlobalLoading } from "../../store/GlobalLoading";
import { IRequestData } from "../../pages/private/Account/ChangePassword/validation";

export function useAuthChangePassword() {
  const { openAlert } = useAlertError();
  const { closeLoading, openLoading } = useGlobalLoading();
  const navigate = useNavigate();

  async function update(body: IRequestData): Promise<any> {
    openLoading();

    try {
      const response = await api.put("/authentication/change-password", body);
      handleResponse(
        response,
        ResponseMessagesEnum.SuccessForgotPassword,
        response.data
      );
      navigate("/signin");
      closeLoading();
    } catch (error: AxiosError | any) {
      handleError(
        ResponseMessagesEnum.ErrorForgotPassword,
        error.response?.data?.message
      );
      closeLoading();
    }
  }

  //#region Response Functions
  function handleResponse(
    response: AxiosResponse,
    defaultMessage: ResponseMessagesEnum,
    message?: string
  ): void {
    if (response.status === 200) {
      openAlert({
        message: message ? message : defaultMessage,
        status: "success",
      });
      closeLoading();
    } else {
      throw new Error(response.data.message);
    }
  }

  function handleError(
    defaultMessage: ResponseMessagesEnum,
    message?: string
  ): void {
    openAlert({
      message: message ? message : defaultMessage,
      status: "error",
    });
  }
  //#endregion

  return { update };
}
