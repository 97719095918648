import { Suspense } from "react";
import { Route } from "react-router-dom";
import { GlobalLoading } from "../components/GlobalLoading";
import ForgotPasswordGuard from "../guards/ForgotPasswordGuard";
import SignUpGuard from "../guards/SignUpGuard";
import { ForgotPassword } from "../pages/public/ForgotPassword";
import { SignUp } from "../pages/public/SignUp";
import { Login } from "../pages/public/SignIn";

export const publicRoute = (
  <>
    <Route
      path="/signin"
      element={
        <Suspense fallback={<GlobalLoading infinityOpen />}>
          <Login />
        </Suspense>
      }
    />
    <Route path="/signup" element={<SignUpGuard />}>
      <Route
        index
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <SignUp.GenerateCode />
          </Suspense>
        }
      />
      <Route
        path="validate"
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <SignUp.ValidateCode />
          </Suspense>
        }
      />
      <Route
        path="complete"
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <SignUp.Complete />
          </Suspense>
        }
      />
    </Route>
    <Route path="/forgot-password" element={<ForgotPasswordGuard />}>
      <Route
        index
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <ForgotPassword.GenerateCode />
          </Suspense>
        }
      />
      <Route
        path="validate"
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <ForgotPassword.ValidateCode />
          </Suspense>
        }
      />
      <Route
        path="complete"
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <ForgotPassword.Complete />
          </Suspense>
        }
      />
    </Route>
  </>
);
