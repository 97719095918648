import { ConfirmPinRescue } from "./ConfirmPinRescue";
import { Logout } from "./Logout";

interface IOptionsModal {
  [key: string]: () => JSX.Element;
}

export const optionsModal: IOptionsModal = {
  Logout,
  ConfirmPinRescue,
};
