import { create } from "zustand";
import { OptionsModalEnum } from "../interfaces/Enums";

interface IModal {
  key: OptionsModalEnum | null;
  isOpen: boolean;
}

interface IModalStore {
  modal: IModal;
  messages: {
    [key: string]: string;
  };
  openModal: (modalKey: OptionsModalEnum, messages?: any) => void;
  closeModal: () => void;
}

const modal: IModal = {
  key: null,
  isOpen: false,
};

export const useModalStore = create<IModalStore>((set) => ({
  modal,
  messages: {},
  openModal: (modalKey, messages) => {
    set(() => ({
      modal: {
        key: modalKey,
        isOpen: true,
      },
      messages: messages || {},
    }));
  },
  closeModal: () => {
    set(() => ({
      modal,
    }));
  },
}));
