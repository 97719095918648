import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useGlobalLoading } from "../../../store/GlobalLoading";
import { PaleteEnum } from "../../../interfaces/Enums";
import * as yup from "yup";
import { useAuthLogin } from "../../../queries/Auth/login";
import { lazy, useEffect, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForgotPassword } from "../../../store/ForgotPassword";
import { useSignUp } from "../../../store/SignUp";

export type ILoginFormData = {
  email: string;
  password: string;
};

const loginFormSchema = yup.object({
  email: yup.string().email("*E-mail Inválido").required("*Campo Obrigatório"),
  password: yup.string().required("*Campo Obrigatório"),
});

export default function Index() {
  const { request } = useAuthLogin();
  const { openLoading } = useGlobalLoading();
  const [showPassword, setShowPassword] = useState(false);
  const { clear } = useForgotPassword();
  const { clearSignUpState } = useSignUp();

  const loginForm = useForm<ILoginFormData>({
    resolver: yupResolver(loginFormSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = loginForm;

  useEffect(() => {
    clear();
    clearSignUpState();
  }, []);

  const onSubmit = (data: ILoginFormData) => {
    openLoading();
    request(data);
  };

  return (
    <Grid
      item
      container
      flex={{ md: 1.5, xs: 2 }}
      flexDirection={"column"}
      justifyContent={{ xs: "flex-start", md: "center" }}
      alignItems={"center"}
    >
      <Paper sx={{ width: "85%" }}>
        <Grid container flexDirection={"column"} p={4}>
          <Typography
            variant="h5"
            textAlign={"center"}
            fontWeight={"bold"}
            mb={4}
          >
            Falta pouco para aproveitar o seu NBA League Pass! Sua jornada
            começa com o login.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container mb={1}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      value={field.value || ""}
                      variant="outlined"
                      placeholder="Email"
                      fullWidth
                    />
                    <Typography variant="caption" mt={0.5} color={"red"}>
                      {errors.email?.message}
                    </Typography>
                  </>
                )}
              />
            </Grid>
            <Grid container mb={1}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      value={field.value || ""}
                      variant="outlined"
                      placeholder="Senha"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {!showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="caption" mt={0.5} color={"red"}>
                      {errors.password?.message}
                    </Typography>
                  </>
                )}
              />
            </Grid>
            <Grid item container flexDirection={"column"} rowGap={2}>
              <Button
                variant={"contained"}
                sx={{
                  bgcolor: PaleteEnum.bluePrimary,
                  "&:hover": {
                    bgcolor: PaleteEnum.bluePrimary,
                  },
                }}
                fullWidth
                type="submit"
              >
                Login
              </Button>
              <Grid
                item
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Link
                  to="/forgot-password"
                  style={{
                    color: PaleteEnum.bluePrimary,
                    fontSize: "0.9rem",
                  }}
                >
                  Esqueceu sua senha?
                </Link>
                <Link
                  to="/signup"
                  style={{
                    color: PaleteEnum.bluePrimary,
                    textDecoration: "none",
                  }}
                >
                  Crie sua conta
                </Link>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
}

export const Login = lazy(() => import("./index"));
