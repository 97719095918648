import { AlertColor } from "@mui/material";
import { v4 } from "uuid";
import { create } from "zustand";

type Alert = {
  key?: string;
  message: string;
  open?: boolean;
  status: AlertColor | undefined;
};

type UseAlertError = {
  alerts: Alert[];
  openAlert: (alert: Alert, time?: number) => void;
  closeAlert: (key: string | undefined) => void;
};

export const useAlertError = create<UseAlertError>((set) => ({
  alerts: [],
  openAlert: (alert: Alert, time = 3000) => {
    const id = v4();

    set((state) => {
      const falseAlerts = state.alerts.map((alert) => ({
        ...alert,
        open: false,
      }));

      return {
        alerts: [...falseAlerts, { ...alert, open: true, key: id }],
      };
    });

    setTimeout(() => {
      set((state) => ({
        alerts: state.alerts.map((alert) =>
          alert.key === id ? { ...alert, open: false } : alert
        ),
      }));
    }, time);
  },
  closeAlert: (key) => {
    set((state) => ({
      alerts: state.alerts.filter((alert) => alert.key !== key),
    }));
  },
}));

export default useAlertError;
