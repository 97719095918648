import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import NBALogo from "../assets/images/NBA-Logo.png";
import { useAuthentication } from "../store/Authentication";
import { useSignUp } from "../store/SignUp";
import { PaleteEnum } from "../interfaces/Enums";

export function PublicGuard() {
  const { isAuthenticated } = useAuthentication();
  const { clearSignUpState } = useSignUp();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith("/signup")) {
      clearSignUpState();
    }
  }, [location.pathname, clearSignUpState]);

  return (
    <Grid
      container
      flexDirection={{ md: "row", xs: "column" }}
      justifyContent={"space-between"}
      flexWrap={"nowrap"}
      width={"full"}
      height={"100vh"}
      rowGap={4}
      bgcolor={{ xs: PaleteEnum.bluePrimary, md: "white" }}
    >
      <Grid
        item
        container
        flex={{ xs: 1.5, sm: 1.5, md: 2.5 }}
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={PaleteEnum.bluePrimary}
      >
        <Grid container justifyContent={"center"}>
          <Box
            width={{ xs: 300, sm: 400, md: 500, lg: 600 }}
            p={2}
            pr={3}
            bgcolor={"white"}
            borderRadius={4}
          >
            <img src={NBALogo} alt="NBA logo" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
      {!isAuthenticated ? <Outlet /> : <Navigate to={"/"} />}
    </Grid>
  );
}
