import { Suspense } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { GlobalLoading } from "../components/GlobalLoading";
import PinGuard from "../guards/PinGuard";
import { Account } from "../pages/private/Account";
import { ChangePassword } from "../pages/private/Account/ChangePassword";
import { PinRescue } from "../pages/private/PinRescue";

export const privateRoute = (
  <>
    <Route path="/" element={<Outlet />}>
      <Route index element={<PinGuard />} />
      <Route path="/pin" element={<PinGuard />}>
        <Route
          path="inactive"
          element={
            <Suspense fallback={<GlobalLoading infinityOpen />}>
              <PinRescue.Inactive />
            </Suspense>
          }
        />
        <Route
          path="active"
          element={
            <Suspense fallback={<GlobalLoading infinityOpen />}>
              <PinRescue.Active />
            </Suspense>
          }
        />
        <Route
          path="external"
          element={
            <Suspense fallback={<GlobalLoading infinityOpen />}>
              <PinRescue.External />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="/account"
        element={
          <Suspense fallback={<GlobalLoading infinityOpen />}>
            <Account />
          </Suspense>
        }
      >
        <Route index element={<Navigate to={"change-password"} />} />
        <Route
          path="change-password"
          element={
            <Suspense fallback={<GlobalLoading infinityOpen />}>
              <ChangePassword />
            </Suspense>
          }
        />
      </Route>
    </Route>
  </>
);
