import { Grid, Typography } from "@mui/material";

export function NotFound() {
  return (
    <Grid
      container
      height={"100vh"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item>
        <Typography variant="h2" fontWeight={"bold"}>
          404
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5">Página não encontrada</Typography>
      </Grid>
    </Grid>
  );
}
