import { lazy } from "react";

const GenerateCode = lazy(() => import("./GenerateCode"));
const ValidateCode = lazy(() => import("./ValidateCode"));
const Complete = lazy(() => import("./Complete"));

export const ForgotPassword = {
  GenerateCode,
  ValidateCode,
  Complete,
};
