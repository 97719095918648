import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { lazy } from "react";
import { useForm } from "react-hook-form";
import { PaleteEnum } from "../../../../interfaces/Enums";
import { useGlobalLoading } from "../../../../store/GlobalLoading";
import {
  FormSchema,
  IFormSchema,
  IRequestData,
  initialData,
} from "./validation";
import { formFields, useFormFields } from "./formFields";
import { useNavigate } from "react-router-dom";
import { useAuthChangePassword } from "../../../../queries/Auth/changePassword";

export default function Index() {
  const { openLoading } = useGlobalLoading();
  const navigate = useNavigate();
  const { update } = useAuthChangePassword();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IFormSchema>({
    resolver: yupResolver(FormSchema),
  });

  const { renderPassword } = useFormFields({ control, errors });

  const onSubmit = (formData: IFormSchema) => {
    const bodySubmit: IRequestData = {
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
    };

    openLoading();
    update(bodySubmit);
    reset(initialData);
  };

  return (
    <Paper sx={{ width: "40%" }}>
      <Grid container flexDirection={"column"} p={4}>
        <Typography variant="h5" textAlign={"center"} mb={4}>
          Alterar senha
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Crie uma senha forte
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 3 }}>
          Crie uma nova senha forte que você não use em outros sites
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container mb={1} rowGap={1}>
            {formFields.map((formField) => renderPassword(formField))}
          </Grid>
          <Grid item container flexDirection={"column"} rowSpacing={1}>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: PaleteEnum.bluePrimary,
                  "&:hover": {
                    bgcolor: PaleteEnum.bluePrimary,
                  },
                }}
                fullWidth
              >
                Salvar senha
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                onClick={() => {
                  reset(initialData);
                  navigate(-1);
                }}
                sx={{ color: PaleteEnum.bluePrimary }}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
}
export const ChangePassword = lazy(() => import("./index"));
