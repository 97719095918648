import { Navigate, Outlet } from "react-router-dom";
import { TopBar } from "../components/TopBar";
import { useAuthentication } from "../store/Authentication";
import { useAuthProfile } from "../queries/Auth/profile";
import { useEffect } from "react";
import { api } from "../services/api";

export function PrivateGuard() {
  const { isAuthenticated, logout } = useAuthentication();
  const { request } = useAuthProfile();

  useEffect(() => {
    if (isAuthenticated) {
      request();
    }
  }, [isAuthenticated]);

  // Configura o interceptor para adicionar o token de autenticação em todas as requisições
  useEffect(() => {
    const interceptor = api.interceptors.request.use((config) => {
      const authToken = sessionStorage.getItem("authToken");
      const csrfToken = sessionStorage.getItem("csrfToken");

      config.headers.Authorization = `Bearer ${authToken}`;
      config.headers["x-access-token"] = authToken;
      config.headers["x-csrf-token"] = csrfToken;

      return config;
    });

    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.request.eject(interceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [logout]);

  return isAuthenticated ? (
    <>
      <TopBar />
      <Outlet />
    </>
  ) : (
    <Navigate to={"/signin"} />
  );
}
