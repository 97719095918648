import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface IProfile {
  nome: string;
  email: string;
  dataFimPrevista?: string;
}

interface ITokens {
  authToken: string;
  csrfToken: string;
}

type UseAuthProps = {
  isAuthenticated: boolean;
  profile: IProfile;
  login: (tokens: ITokens) => void;
  logout: () => void;
  setProfile: (dataProfile: IProfile) => void;
};

const profile = {
  nome: "",
  email: "",
  dataFimPrevista: "",
};

export const useAuthentication = create(
  persist<UseAuthProps>(
    (set) => ({
      isAuthenticated: false,
      profile: profile,
      login: (tokens) => {
        const { authToken, csrfToken } = tokens;
        sessionStorage.setItem("authToken", authToken);
        sessionStorage.setItem("csrfToken", csrfToken);

        set((state) => {
          return {
            ...state,
            isAuthenticated: !!authToken && !!csrfToken,
          };
        });
      },
      logout: () => {
        set((state) => {
          sessionStorage.clear();
          return { ...state, profile, isAuthenticated: false };
        });
      },
      setProfile: (profileData: IProfile) => {
        set((state) => {
          return {
            ...state,
            profile: profileData,
          };
        });
      },
    }),
    {
      name: "authentication",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
