import { Outlet } from "react-router-dom";
import { GlobalErrorMessage } from "./components/GlobalErrorMessage";
import { GlobalLoading } from "./components/GlobalLoading";
import { GlobalModalStore } from "./components/Modal";
import { CssBaseline } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { InternalServerError } from "./pages/InternalServerError";

function App() {
  return (
    <>
      {/* Reseta o css global */}
      <CssBaseline />
      <GlobalLoading />
      <GlobalErrorMessage />
      <GlobalModalStore />
      <ErrorBoundary FallbackComponent={InternalServerError}>
        <Outlet />
      </ErrorBoundary>
    </>
  );
}

export default App;
