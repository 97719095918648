import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ISignUpState {
  code: string;
  email: string;
  isValidationCode: boolean;
}

interface ISignUp {
  signUpState: ISignUpState;
  setCode: (code: string) => void;
  setEmail: (email: string) => void;
  setValidationCode: () => void;
  clearSignUpState: () => void;
}

const initialState: ISignUpState = {
  code: "",
  email: "",
  isValidationCode: false,
};

export const useSignUp = create(
  persist<ISignUp>(
    (set) => ({
      signUpState: initialState,
      setCode: (code) => {
        set((state) => ({
          signUpState: {
            ...state.signUpState,
            code,
          },
        }));
      },
      setEmail: (email) => {
        set((state) => ({
          signUpState: {
            ...state.signUpState,
            email,
          },
        }));
      },
      setValidationCode: () => {
        set((state) => ({
          signUpState: {
            ...state.signUpState,
            isValidationCode: true,
          },
        }));
      },
      clearSignUpState() {
        set(() => ({
          signUpState: initialState,
        }));
      },
    }),
    {
      name: "signup",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
