import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import * as React from "react";
import { useModalStore } from "../../store/ModalStore";
import { optionsModal } from "./options";
import { OptionsModalEnum } from "../../interfaces/Enums";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function GlobalModalStore() {
  const { modal, closeModal } = useModalStore();

  const key = modal.key as OptionsModalEnum;
  let SelectedModal = () => <></>;

  if (key) {
    SelectedModal = optionsModal[key];
  }

  return (
    <Dialog
      open={modal.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-describedby="alert dialog"
    >
      <SelectedModal />
    </Dialog>
  );
}
