import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useAlertError } from "../store/AlertError";
import Slide, { SlideProps } from "@mui/material/Slide";

type TransitionProps = Omit<SlideProps, "direction">;

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export function GlobalErrorMessage() {
  const { alerts, closeAlert } = useAlertError();

  return (
    <>
      {alerts.map((alert, index) => (
        <Snackbar
          key={alert.key}
          open={alert.open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          TransitionComponent={Transition}
          autoHideDuration={3000 + index * 1000}
          // onClose={() => closeAlert(alert.key)}
        >
          <Alert
            variant="filled"
            icon={
              alert.status === "info" ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : null
            }
            onClose={() => closeAlert(alert.key)}
            severity={alert.status}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
}
