import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAuthentication } from "../../store/Authentication";
import { useGlobalLoading } from "../../store/GlobalLoading";
import { useModalStore } from "../../store/ModalStore";

export function Logout() {
  const { closeModal } = useModalStore();
  const { logout } = useAuthentication();
  const { openLoading, closeLoading } = useGlobalLoading();

  return (
    <>
      <DialogTitle>{"Deseja realmente sair?"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          Caso saia, terá de fazer login novamente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "#17408B" }}
          onClick={() => {
            closeModal();
            openLoading();
            setTimeout(() => {
              closeLoading();
              logout();
            }, 500);
          }}
        >
          Sair
        </Button>
      </DialogActions>
    </>
  );
}
