import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import useAlertError from "../store/AlertError";

export function InternalServerError({ error, resetErrorBoundary }: any) {
  const { openAlert } = useAlertError();

  useEffect(() => {
    openAlert({
      message: "Erro interno do servidor",
      status: "error",
    });
  }, [openAlert]);

  return (
    <Grid
      container
      height={"100vh"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item mb={2}>
        <Typography variant="h2" fontWeight={"bold"}>
          500
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h5">Erro interno do servidor</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{error?.message}</Typography>
      </Grid>
    </Grid>
  );
}
