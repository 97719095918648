import Logout from "@mui/icons-material/Logout";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/NBA-Logo__withoutText.png";
import { useAuthentication } from "../store/Authentication";
import { useModalStore } from "../store/ModalStore";
import { OptionsModalEnum, PaleteEnum } from "../interfaces/Enums";
import LockResetIcon from "@mui/icons-material/LockReset";

export function TopBar() {
  const navigate = useNavigate();
  const { profile } = useAuthentication();
  const { openModal } = useModalStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }} pt={8}>
      <MuiAppBar position="fixed" sx={{ bgcolor: PaleteEnum.bluePrimary }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title="Nba">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                img: {
                  width: "100%",
                },
              }}
              width={50}
              p={0.5}
              aria-label="tela inicial"
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo Playhub" />
            </Box>
          </Tooltip>
          <Box>
            <Tooltip title="Configurações da conta">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {profile.nome[0]}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar>{profile.nome[0]}</Avatar>
              {profile.nome}
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                navigate("/account/change-password");
              }}
            >
              <ListItemIcon>
                <LockResetIcon fontSize="medium" />
              </ListItemIcon>
              Trocar senha
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                openModal(OptionsModalEnum.Logout);
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Sair
            </MenuItem>
          </Menu>
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
}
