import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { IFormSchema } from "./validation";

interface IUseFormFields {
  control: Control<IFormSchema, any>;
  errors: FieldErrors<IFormSchema>;
}

interface IFormFields {
  name: keyof IFormSchema;
  label: string;
  placeholder: string;
}

export const formFields: IFormFields[] = [
  {
    name: "currentPassword",
    label: "Senha Atual",
    placeholder: "Senha",
  },
  {
    name: "newPassword",
    label: "Nova Senha",
    placeholder: "Confirme sua senha",
  },
  {
    name: "confirmNewPassword",
    label: "Confirme a Nova Senha",
    placeholder: "Confirme sua senha",
  },
];

export const useFormFields = (props: IUseFormFields) => {
  const [showPassword, setShowPassword] = useState(false);
  const { control, errors } = props;

  const renderPassword = (formField: IFormFields) => (
    <>
      <Controller
        name={formField.name}
        control={control}
        render={({ field }) => (
          <>
            <TextField
              {...field}
              value={field.value || ""}
              variant="outlined"
              placeholder={formField.placeholder}
              label={formField.label}
              type={showPassword ? "text" : "password"}
              error={!!errors[formField.name]}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="caption" mt={0.5} color="red">
              {errors[formField.name]?.message}
            </Typography>
          </>
        )}
      />
    </>
  );

  return { renderPassword };
};
