import { AxiosError } from "axios";
import { api } from "../../services/api";
import { useAlertError } from "../../store/AlertError";
import { useAuthentication } from "../../store/Authentication";
import { useAuthorization } from "../../store/Authorization";
import { useGlobalLoading } from "../../store/GlobalLoading";

interface IResponseData {
  nome: string;
  email: string;
  dataFimPrevista?: string;
  situacao: number;
  message: string;
}

export function useAuthProfile() {
  const { setProfile, logout } = useAuthentication();
  const { setLeaguePass } = useAuthorization();
  const { openAlert } = useAlertError();
  const { openLoading, closeLoading } = useGlobalLoading();

  async function request(): Promise<void> {
    openLoading();

    try {
      const response = await api.get(`/authentication/profile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        },
      });

      const { situacao, message, ...rest } = response.data as IResponseData;

      setLeaguePass(situacao);
      setProfile({ ...rest });
      closeLoading();
    } catch (error: AxiosError | any) {
      logout();
      handleError(error);
      closeLoading();
    }
  }

  function handleError(error: AxiosError): void {
    const { response } = error;

    if (response) {
      const { status = 999, data } = response;

      openAlert({
        message: "Erro ao carregar a sua conta, faça login novamente",
        status: "error",
      });
    } else {
      openAlert({
        message: "Erro de rede ou outro problema",
        status: "error",
      });
    }
  }

  return { request };
}
