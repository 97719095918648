import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import { PrivateGuard } from "../guards/PrivateGuard";
import { PublicGuard } from "../guards/PublicGuard";
import { NotFound } from "../pages/NotFound";
import { privateRoute } from "./private";
import { publicRoute } from "./public";

const baseName = process.env.PUBLIC_URL;

export default function Router() {
  return (
    <BrowserRouter basename={baseName}>
      <Routes>
        <Route element={<App />}>
          <Route element={<PrivateGuard />}>{privateRoute}</Route>
          <Route element={<PublicGuard />}>{publicRoute}</Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
