import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface IData {
  code: string;
  email: string;
  isValidationCode: boolean;
}

interface IState {
  data: IData;
  setCode: (code: string) => void;
  setEmail: (email: string) => void;
  onValidationCode: () => void;
  clear: () => void;
}

const initialState: IData = {
  code: "",
  email: "",
  isValidationCode: false,
};

export const useForgotPassword = create(
  persist<IState>(
    (set) => ({
      data: initialState,
      setCode: (code) => {
        set((state) => ({
          data: {
            ...state.data,
            code,
          },
        }));
      },
      setEmail: (email) => {
        set((state) => ({
          data: {
            ...state.data,
            email,
          },
        }));
      },
      onValidationCode: () => {
        set((state) => ({
          data: {
            ...state.data,
            isValidationCode: true,
          },
        }));
      },
      clear() {
        set(() => ({
          data: initialState,
        }));
      },
    }),
    {
      name: "forgotpassword",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
